import InternalHeader from './internalHeader'
import styles from './blogPostHeader.module.scss'
import AvenueImg from '../components/AvenueImg'
import React from 'react'
import styled from 'styled-components'

const Img = styled(AvenueImg)`
  height: 100%;
  width: 100%;

  img {
    max-width: 100%;
  }
`

const BlogPostHeader = ({ blogPost }) => {
  return (
    <div className={`${styles.header} ${styles[blogPost.avBlog.type]}`}>
      <InternalHeader
        title={`${blogPost.seo.title}`}
        headerTitle="The Latest"
        description={blogPost.seo.metaDesc}
        flag="OUR BLOG"
        img="header_big_full_cziaau"
        template="background"
        size="blogpost"
      />

      <div className={`${styles.blogPostHeaderContainer}`}>
        {blogPost.avBlog.type === 'article' && (
          <div className={styles.imgWrapper}>
            <Img fluid={blogPost.headerSettings?.headerImage} />
            {/* <Img user="avenue" publicId={blogPost.frontmatter.headerImage} alt="" /> */}
            <div className={styles.overlay} />
          </div>
        )}

        <div className={styles.headerTextContainer}>
          <span>
            <p>{blogPost.date}</p>
            <p>|</p>
            <p>{blogPost.tags?.nodes[0]?.name || 'Article'}</p>
          </span>

          <p>{blogPost.title}</p>
          <p className={styles.roundUpText}>
            'Roundup' is the collection of key stuff we've either been reading,
            discussing or working on during the month at Avenue.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BlogPostHeader
