import React from 'react'
import AvenueImg from '../../components/AvenueImg'
import {
  ImgContainer,
  ImgText__container,
  TextContainer,
  Header,
  Description,
  TextImageLink,
} from './style'

const TextImage = ({
  image,
  heading,
  description,
  text2,
  link,
  index,
  linkText,
}) => {
  const isOdd = index % 2 == 1
  return (
    <ImgText__container>
      <ImgContainer isOdd={isOdd}>
        <AvenueImg fluid={image} />
      </ImgContainer>

      <TextContainer isOdd={isOdd}>
        <Header>{heading}</Header>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {text2 && <Description>{text2}</Description>}

        {linkText && (
          <TextImageLink target="_blank" rel="noopener noreferrer" href={link}>
            {linkText}
          </TextImageLink>
        )}
      </TextContainer>
    </ImgText__container>
  )
}
export default TextImage
