import styled from 'styled-components'
import { media } from '../../utils/style-utils'
import Link from '../blogLink'

export const ImgText__container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1680px;
  margin: 0 auto;
  position: relative;

  ${media.medium`
    display: grid;
    grid-template-columns: 1fr 1fr; 
  `}
`

export const ImgContainer = styled.div`
  position: relative;
  img {
    object-fit: cover;

    ${media.medium`
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width: 100%;
      `}
  }
  > div {
    min-height: 100%;
    width: 100%;
  }

  ${media.medium`
    order:${(props) => props.isOdd && 2};
    `}
`

export const TextContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.medium`
  padding: 60px;
    order:${(props) => props.isOdd && 1};
  `}

  ${media.large`
  padding: 120px;
  `}
`

export const Header = styled.h2`
  font-size: 36px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 25px;
  ${media.medium`

    font-size: 30px;
    margin-bottom: 15px;
  `}

  ${media.large`
    font-size: 42px;
    margin-bottom: 20px;


  `}

  ${media.xlarge`
    font-size: 48px;
    margin-bottom: 30px;

  `}
`

export const Description = styled.p`
  color: #869599;
  font-size: 18px;
  letter-spacing: -0.14px;
  line-height: 1.55;
  font-weight: 200;
  margin-bottom: 30px;

  ${media.medium`
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 1.40;


  `}

  ${media.large`
    margin-bottom: 25px;
    font-size: 22px;
    line-height: 1.55;


  `}

  ${media.xlarge`
    margin-bottom: 40px;
    line-height: 34px;


  `}
`

export const TextImageLink = styled(Link)`
  color: #bc237d;
  font-size: 18px;
  font-weight: 500;

  ${media.medium`
    font-size: 20px;
  `}

  ${media.large`
    font-size: 22px;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
  }
`
