import React from 'react'
import { graphql } from 'gatsby'
import GetInTouch from '../../components/getInTouch'
import Author from '../../components/author'
import HtmlText from '../../components/htmlText'
import BlogPostHeader from '../../components/blogPostHeaderV2'
import GlobalComponents from '../../components/globalcomponents'
import SocialMeta from '../../components/socialMeta'
import { TextImage } from '../../components/TextImage'
import { parseBlockContent } from '../../components/parseBlockContent'
import HtmlTextNew from '../../components/htmlTextNew'

const Post = ({ data }) => {
  const { uri, seo, content, avBlog } = data.post || {}

  return (
    <GlobalComponents>
      <SocialMeta
        twitterImage={seo.opengraphImage?.sourceUrl}
        ogImage={seo.opengraphImage?.sourceUrl}
        ogTitle={seo.title}
        ogURL={uri}
      />

      <BlogPostHeader blogPost={data.post} />
      {content && <HtmlTextNew htmlBlock={parseBlockContent(content)} />}
      {avBlog?.roundupItems &&
        avBlog?.roundupItems?.map((item, index) => {
          const { description, heading, image, link, linkText } = item
          return (
            <TextImage
              description={description}
              heading={heading}
              image={image}
              link={link}
              linkText={linkText}
              index={index}
            />
          )
        })}

      {/* <Author template="Brenton" /> */}
      <GetInTouch
        left="Think we might be a good fit for your project?"
        right="Let's have a chat"
        action="/contact"
        cols={[9, 1, 2]}
      />
    </GlobalComponents>
  )
}

export default Post

export const pageQuery = graphql`
  query ($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      uri
      excerpt
      content
      date(formatString: "DD MMM YY")
      tags {
        nodes {
          name
        }
      }
      headerSettings {
        headerImage {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      avBlog {
        type
        roundupItems {
          heading
          description
          link
          linkText
          image {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
      }
      seo {
        metaDesc
        title
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`
